import {
    IonContent, IonGrid, IonRow, IonCol, IonSearchbar, IonButton, IonList,
    IonListHeader, IonLabel, IonItem, IonBadge, IonInput, IonImg, IonAvatar, IonIcon
} from '@ionic/react';
import './SpotHistory.css';
import React, { useState } from 'react';

export interface SpotsHistoryData {
    id: number
    user_name: string
    spot_name: string
    imagePath: string
    latitude: string
    longitude: string
    comments: string
    too_far_flag: string
    distance: string
    status: string
    date: string
}

interface SpotsHistoryProps {
    data: any[]
}

const spotsHistory: React.FC<SpotsHistoryProps> = (props) => {

    const {data} = props
    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonList className='spots-history'>
                        <IonListHeader lines="inset">
                            <IonLabel class='idCol'>ID</IonLabel>
                            <IonLabel class='nameCol'>User Name</IonLabel>
                            <IonLabel class='nameCol'>Spot Name</IonLabel>
                            <IonLabel class='imageCol'>Image Path</IonLabel>
                            <IonLabel class='col-120'>Comments</IonLabel>
                            <IonLabel class='col-120'>Distance</IonLabel>
                            <IonLabel class='col-120'>Status</IonLabel>
                            <IonLabel class='col-120'>Date</IonLabel>
                        </IonListHeader>
                        { data && data.map((d) => <IonItem>
                            <IonLabel class='idCol'>{d.id}</IonLabel>
                            <IonLabel class='nameCol'>{d.user_name}</IonLabel>
                            <IonLabel class='nameCol'>{d.spot_name}</IonLabel>
                            <IonLabel class='imageCol'>{d.imagePath}</IonLabel>
                            <IonLabel class='col-120'>{d.comments}</IonLabel>
                            <IonLabel class='col-120'>{d.distance}</IonLabel>
                            <IonLabel class='col-120'>{d.status}</IonLabel>
                            <IonLabel class='col-120'>{d.date}</IonLabel>
                        </IonItem>)}
                    </IonList>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default spotsHistory;
