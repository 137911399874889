import {
    IonContent, IonGrid, IonRow, IonCol, IonSearchbar, IonButton, IonList,
    IonListHeader, IonLabel, IonItem, IonBadge, IonInput, IonImg, IonAvatar, IonIcon
} from '@ionic/react';
import './SpotsList.css';
import React, { useState } from 'react';

export interface SpotsData {
    id: number
    name: string
    defaultImage: string
    video_image: string
    address: string
    latitude: string
    longitude: string
    existing_new: string
    assigned_PS: string
    assigned_PC: string
    date: string
    category: string
    spot_status: string
}

interface SpotsListProps {
    data: any[]
}

const SpotsList: React.FC<SpotsListProps> = (props) => {

    const {data} = props
    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonList className='spots-list'>
                        <IonListHeader lines="inset">
                            <IonLabel class='idCol'>ID</IonLabel>
                            <IonLabel class='nameCol'>Names</IonLabel>
                            <IonLabel class='imageCol'>Default Image</IonLabel>
                            <IonLabel class='addressCol'>Address</IonLabel>
                            <IonLabel class='col-160'>Latitude</IonLabel>
                            <IonLabel class='col-160'>Longitude</IonLabel>
                            <IonLabel class='col-120'>Existing-New</IonLabel>
                            <IonLabel class='col-160'>Assign_PS</IonLabel>
                            <IonLabel class='col-160'>Assign_PC</IonLabel>
                            <IonLabel class='col-120'>Date</IonLabel>
                            <IonLabel class='col-120'>Category</IonLabel>
                            <IonLabel class='col-120'>Spot Status</IonLabel>
                        </IonListHeader>
                        { data && data.map((d) => <IonItem>
                            <IonLabel class='idCol'>{d.id}</IonLabel>
                            <IonLabel class='nameCol'>{d.name}</IonLabel>
                            <IonLabel class='imageCol'>{d.defaultImage}</IonLabel>
                            <IonLabel class='addressCol'>{d.address}</IonLabel>
                            <IonLabel class='col-160'>{d.latitude}</IonLabel>
                            <IonLabel class='col-160'>{d.longitude}</IonLabel>
                            <IonLabel class='col-120'>{d.existing_new}</IonLabel>
                            <IonLabel class='col-160'>{d.assigned_PS}</IonLabel>
                            <IonLabel class='col-160'>{d.assigned_PC}</IonLabel>
                            <IonLabel class='col-120'>{d.date}</IonLabel>
                            <IonLabel class='col-120'>{d.category}</IonLabel>
                            <IonLabel class='col-120'>{d.spot_status}</IonLabel>
                        </IonItem>)}
                    </IonList>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default SpotsList;
