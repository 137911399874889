import { IonAvatar, IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { menu } from 'ionicons/icons';
// import ExploreContainer from '../components/ExploreContainer';
import './header.css';
import {useSignOut } from "react-auth-kit"
import { useHistory } from 'react-router';

const Header: React.FC = () => {
    const history = useHistory();
    const SignOut = useSignOut();

    const signOut = ()=>{
        SignOut();
        history.push("/login");

    }

    const toggleMenu = () => {
        const splitPane = document.querySelector('ion-split-pane');
        const windowWidth = window.innerWidth;
        const splitPaneShownAt = 992;
        const when = `(min-width: ${splitPaneShownAt}px)`;
        if (windowWidth >= splitPaneShownAt) {
            // split pane view is visible
            const open = (splitPane as HTMLIonSplitPaneElement).when === when;
            (splitPane as HTMLIonSplitPaneElement).when = open ? false : when;
        } else {
            // split pane view is not visible
            // toggle menu open
            const menu = (splitPane as HTMLIonSplitPaneElement).querySelector('ion-menu');
            return (menu as HTMLIonMenuElement).open();
        }
    }
    return (
        // <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => toggleMenu()}>
                            <IonIcon icon={menu} />
                        </IonButton>
                    </IonButtons>
                    <IonItem lines='none'>
                        <IonTitle>Title</IonTitle>
                        <IonChip class="ion-margin-start" slot='end' onClick={()=>signOut()}>
                            <IonAvatar>
                                <img src="https://ionicframework.com/docs/demos/api/avatar/avatar.svg" alt='profile-img' />
                            </IonAvatar>
                            <IonLabel >Sign Out</IonLabel>
                        </IonChip>
                    </IonItem>
                </IonToolbar>
            </IonHeader>
        // </IonPage>
    );
};

export default Header;
