import {
    IonContent, IonGrid, IonRow, IonCol, IonSearchbar, IonButton, IonList,
    IonListHeader, IonLabel, IonItem, IonBadge, IonInput, IonImg, IonAvatar, IonIcon
} from '@ionic/react';
import './LullTime.css';
import React, { useState } from 'react';

export interface LullTimeData {
    id: number
    username: string
    spot_name: string
    zone: string
    subZone: string
    chowky: string
    value1: string
    value2: string
    value3: string
    value4: string
    value5: string
    value6: string
    value7: string
    value8: string
    value9: string
    value10: string
    value11: string
    value12: string
    value13: string
    value14: string
    value15: string
    value16: string
    value17: string
    value18: string
    value19: string
    value20: string
    value21: string
    value22: string
    value23: string
    value24: string
}

interface LullTimeProps {
    data: any[]
}

const LullTime: React.FC<LullTimeProps> = (props) => {

    const {data} = props
    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonList className='lull-time'>
                        <IonListHeader lines="inset">
                            <IonLabel class='idCol'>ID</IonLabel>
                            <IonLabel class='nameCol'>User Name</IonLabel>
                            <IonLabel class='nameCol'>Spot Name</IonLabel>
                            <IonLabel class='nameCol'>Police Station</IonLabel>
                            <IonLabel class='nameCol'>Chowky</IonLabel>
                            <IonLabel class='nameCol'>Zone</IonLabel>
                            <IonLabel class='col-120'>Value1</IonLabel>
                            <IonLabel class='col-120'>Value2</IonLabel>
                            <IonLabel class='col-120'>Value3</IonLabel>
                            <IonLabel class='col-120'>Value4</IonLabel>
                            <IonLabel class='col-120'>Value5</IonLabel>
                            <IonLabel class='col-120'>Value6</IonLabel>
                            <IonLabel class='col-120'>Value7</IonLabel>
                            <IonLabel class='col-120'>Value8</IonLabel>
                            <IonLabel class='col-120'>Value9</IonLabel>
                            <IonLabel class='col-120'>Value10</IonLabel>
                            <IonLabel class='col-120'>Value11</IonLabel>
                            <IonLabel class='col-120'>Value12</IonLabel>
                            <IonLabel class='col-120'>Value13</IonLabel>
                            <IonLabel class='col-120'>Value14</IonLabel>
                            <IonLabel class='col-120'>Value15</IonLabel>
                            <IonLabel class='col-120'>Value16</IonLabel>
                            <IonLabel class='col-120'>Value17</IonLabel>
                            <IonLabel class='col-120'>Value18</IonLabel>
                            <IonLabel class='col-120'>Value19</IonLabel>
                            <IonLabel class='col-120'>Value20</IonLabel>
                            <IonLabel class='col-120'>Value21</IonLabel>
                            <IonLabel class='col-120'>Value22</IonLabel>
                            <IonLabel class='col-120'>Value23</IonLabel>
                            <IonLabel class='col-120'>Value24</IonLabel>
                        </IonListHeader>
                        { data && data.map((d) => <IonItem>
                            <IonLabel class='idCol'>{d.id}</IonLabel>
                            <IonLabel class='nameCol'>{d.username}</IonLabel>
                            <IonLabel class='nameCol'>{d.spot_name}</IonLabel>
                            <IonLabel class='nameCol'>{d.subZone}</IonLabel>
                            <IonLabel class='nameCol'>{d.chowky}</IonLabel>
                            <IonLabel class='nameCol'>{d.zone}</IonLabel>
                            <IonLabel class='col-120'>{d.value1}</IonLabel>
                            <IonLabel class='col-120'>{d.value2}</IonLabel>
                            <IonLabel class='col-120'>{d.value3}</IonLabel>
                            <IonLabel class='col-120'>{d.value4}</IonLabel>
                            <IonLabel class='col-120'>{d.value5}</IonLabel>
                            <IonLabel class='col-120'>{d.value6}</IonLabel>
                            <IonLabel class='col-120'>{d.value7}</IonLabel>
                            <IonLabel class='col-120'>{d.value8}</IonLabel>
                            <IonLabel class='col-120'>{d.value9}</IonLabel>
                            <IonLabel class='col-120'>{d.value10}</IonLabel>
                            <IonLabel class='col-120'>{d.value11}</IonLabel>
                            <IonLabel class='col-120'>{d.value12}</IonLabel>
                            <IonLabel class='col-120'>{d.value13}</IonLabel>
                            <IonLabel class='col-120'>{d.value14}</IonLabel>
                            <IonLabel class='col-120'>{d.value15}</IonLabel>
                            <IonLabel class='col-120'>{d.value16}</IonLabel>
                            <IonLabel class='col-120'>{d.value17}</IonLabel>
                            <IonLabel class='col-120'>{d.value18}</IonLabel>
                            <IonLabel class='col-120'>{d.value19}</IonLabel>
                            <IonLabel class='col-120'>{d.value20}</IonLabel>
                            <IonLabel class='col-120'>{d.value21}</IonLabel>
                            <IonLabel class='col-120'>{d.value22}</IonLabel>
                            <IonLabel class='col-120'>{d.value23}</IonLabel>
                            <IonLabel class='col-120'>{d.value24}</IonLabel>
                        </IonItem>)}
                    </IonList>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default LullTime;
