import { IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonPage, IonRow, IonTitle, IonToolbar,IonPopover,IonDatetime,IonSelect,useIonLoading,IonSelectOption,IonRippleEffect,IonText } from '@ionic/react';
import { fileTrayFullOutline, image, timeOutline } from 'ionicons/icons';
import Header from '../components/header/header';
import './ImageHistory.css';
import React from 'react';
import axios from 'axios';
import { useState, useEffect } from "react";
import { url } from 'inspector';
import { any } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { render } from 'react-dom';
import Menu from '../components/menu/menu';

const ImageHistory: React.FC = () => {

  const { state } = useLocation();
     const [present, dismiss] = useIonLoading();
    const [date, setDate]= React.useState('');
     let image_data: any;
     let selectedUser: any;
     let history = useHistory();
    const [selectedChowky, setSelectedChowky] = React.useState({
    chowky: String,
 });

    let fetchValuesFromPicker = (e:any)=>{
        console.log(e);
        let tempDate = new Date(e);
        var changedDate = ConvertDate(tempDate);
        setDate(changedDate);
       // getLullTimeData(changedDate);
    }

     let ConvertDate = (date:any)=>{
        let mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");

    }

    const [SelectedStation, setSelectedStation] = React.useState([{
    subZone:" ",
    chowky_data:[],
    isSelected: 'false'
  }]);

    const [stations, setStatios] = React.useState([{
    subZone:String,
    chowky_data:[],
    isSelected: 'false'
  }]);

  const [search, setSearchdata] = React.useState<[{
    name:String,
    imagePath: string,
    status: String,
    last_update: String,
    user_id: Number,
    spot_id: Number,
    spot_data: any,
    user_data:any
  }]>();

  const [searchNew, setNewSearchdata] = React.useState<[{
    name:String,
    imagePath: string,
    status: String,
    last_update: String,
    user_id: Number,
    spot_id: Number,
    spot_data: any,
    user_data:any
  }]>();

  const [spot, setSpotdata] = React.useState([{
    name:String
    
  }]);

  const [userId, setuserid] = React.useState([{
    user_id:Number
  }]);

  const [spotId, setspotid] = React.useState([{
    spot_id:Number
  }]);

  const [user, setUserdata] = React.useState([{
    name:String
  }]);

  const [selectedChowkyList, setSelectedChowkyList] = React.useState([{
  chowky: String,
}]);

  const [team, setTeam] = React.useState([{
    name: String,
    isSelected: 'false'
  }]);

  const [selectedTeam, setSelectedTeam] = React.useState({
      name: String,
      id:""
  });

  const [spot_data, setSpot_data] = React.useState([
    {
      name: String,
      isSelected: 'false'
    }
  ]);

let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    }
  };

 useEffect(() => {
   // window.location.reload();
   console.log(history.location.state);
    getWeekImages();
    // getChowkyDataDropDown();
    // getTeamDataDropdown();
    // getSpotDropdown();
    var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        fetchValuesFromPicker(date);
  }, []);

    let selectStation = (e: any) => {
    if (e.detail != undefined && e.detail.value != "") {
      if (stations && (stations.length > 0)) {
        stations.forEach(item => {
          item.isSelected = 'false';
        })
        stations.forEach(item => {
          if (item.subZone === e.detail.value.subZone) {
            item.isSelected = 'true';
            setSelectedStation(e.detail.value);
            setSelectedChowkyList(e.detail.value.chowky_data);
          }
        })
      }
    }
  }

  let userWiseFilter = (e: any) => {
    if (e.detail != undefined && e.detail.value != "") {
      if (searchNew && (searchNew.length > 0)) {
        var filteredArray = searchNew.filter(function (el) {
            return el.user_id == e.detail.value.id;
        })
        selectedUser = e.detail.value.id;
        setuserid(e.detail.value.id);
        setSearchdata(filteredArray as any);
        setNewSearchdata(searchNew);
      } else {
        alert('Please select Police Station and Chowky to filter data first.');
      }
    }
  }

  let spotWiseFilter = (userId: any, spot_id: any) => {
    if (userId == undefined) {
      userId = ""
    }
    console.log(searchNew);
    if (spot_id.detail != undefined && spot_id.detail.value != "") {
      if (searchNew && (searchNew.length > 0)) {
        var filteredArray = searchNew.filter(function (el) {
            return el.spot_id == spot_id.detail.value.id;
        })
        setspotid(spot_id.detail.value.id);
        setSearchdata(filteredArray as any);
        setNewSearchdata(searchNew);
      } else {
        alert('Please select Police Station and Chowky to filter data first.');
      }
    }
  }

  let selectChowky = (e:any)=>{

    if(selectedChowkyList&& selectedChowkyList.length>0){
      setSelectedChowky(e.detail.value.chowky);
    }

  }

  let selectTeam = (e: any) => {
    // if (e.detail.value != undefined && e.detail.value != "") {
    //   // setSelectedTeam(e.detail.value.name);
    //   selectedTeam = e.detail.value;
    //   console.log("This is seleted team>", selectedTeam);
    //   if(e.detail.value){

    //     // window.localStorage.setItem("selectedTeam", e.detail.value.name);
    //   }
    if (team && (team.length > 0)) {
      team.forEach(item => {
        item.isSelected = 'false';
      })
      team.forEach(item => {
        if (item.name === e.detail.value.name) {
          item.isSelected = 'true';
          setSelectedTeam(e.detail.value);
        }
      })
    }

  }

  let getTeamDataDropdown = () => {
    const payload = { "user_id": "9" };
    // present({
    //     message: "Loading...",
    //   });
    axios.post('/getTeamData', payload, axiosConfig)
      .then(function (response) {
        setTeam(response.data.team_data);
        dismiss();
        // console.log("this is team data", team);
      })
      .catch(function (error) {
        console.log(error);
        dismiss();
      });
  }

  let getChowkyDataDropDown = () => {
    present({
      message: "Loading...",
    });
    axios.post('/getChowkyData', {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: {}
    })
      .then(function (response) {
        console.log("this is chowky data ",response);
        setStatios(response.data.chowky_data);
        dismiss();
      })
      .catch(function (error) {
        console.log(error);
        dismiss();
      });
  }

  let getWeekImages = () => {
    present({
      message: "Loading...",
    });
    var today = new Date(),
    currentdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const payload = { "date": currentdate, "spot_id": history.location.state };
    axios.post('/getWeekTodaysImages', payload, axiosConfig)
      .then(function (response) {
        if(response.data.image_data) {
            image_data = response.data.image_data
            setSearchdata(image_data);
            setNewSearchdata(image_data);
            setUserdata(image_data.user_data);
            setSpotdata(image_data.spot_data);
            dismiss();
        } else {
          dismiss();
        }
      })
      .catch(function (error) {
        console.log(error);
        dismiss();
      });
  }

   const [selectedSpot, setSelectedSpot] = React.useState(
      {
          name: String,
          id:"",
      }
  );

  let getSpotDropdown = () => {
    const payload = { "user_id": "9" };
    // present({
    //     message: "Loading...",
    //   });
    axios.post('/getSpotDropdown', payload, axiosConfig)
      .then(function (response) {
        //   console.log(response);
        setSpot_data(response.data.spot_data);
        dismiss();
        //   console.log("this is spot data",spot_data);
      })
      .catch(function (error) {
        console.log(error);
        dismiss();
      });
  }

   let selectSpot = (e: any) => {
    if (e.detail.value != undefined && e.detail.value !== "") {
      // setSelectedSpot(e.detail.value.name);
      // selectedSpot = e.detail.value;
      // if(e.detail.value){
      //   // window.localStorage.setItem("selectedSpot", e.detail.value.name);
      // }
      if (spot_data && (spot_data.length > 0)) {
        spot_data.forEach(item => {
          item.isSelected = 'false';
        })
        spot_data.forEach(item => {
          if (item.name === e.detail.value.name) {
            item.isSelected = 'true';
            setSelectedSpot(e.detail.value);
            // selectedSpot = e.detail.value;
          }
        })
      }



    }
  }

  let getSearchedData = (selectedChowkyData: any, policeStation: any, selectedDate: any, ) => {
    if (selectedChowkyData == undefined) {
      selectedChowkyData = ""
    }
    if (policeStation == undefined) {
      policeStation = ""
    }
    if (selectedDate == undefined) {
      selectedDate = ""
    }

    const payload = { "chowky": selectedChowkyData, "police_station": policeStation.subZone, "date": selectedDate,  };
    present({
      message: "Please wait...",
    });
    axios.post('/getTodaysImages', payload, axiosConfig)
      .then(function (response) {
        if(response.data.image_data) {
            image_data = response.data.image_data
            setSearchdata(image_data);
            setNewSearchdata(image_data);
            setUserdata(image_data.user_data);
            setSpotdata(image_data.spot_data);
            setSpot_data(response.data.spot_list);
            setTeam(response.data.user_list);
            dismiss();
      } else {
        dismiss();
      }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
    return (
        <IonPage>
            <Header />
            <IonContent fullscreen id='imageHistory'>
                <IonGrid>
                    <IonRow>
                        {
                            search && search.length > 0 ?
                             (search.map((item, i) => {
                                return (
                                    <IonCol size='3'>
                            <IonCard>
                                <img className="spotImage" alt="Spot Image" src= {item.imagePath} />

                                <IonCardContent>
                                    <IonCardSubtitle class='mb-10'><b>Spot : </b>{item.spot_data.name}</IonCardSubtitle>
                                    <IonCardSubtitle><b>User : </b>{item.user_data.name}</IonCardSubtitle>
                                    <IonCardSubtitle><b>Date : </b>{item.last_update}</IonCardSubtitle>
                                    <IonCardSubtitle class='mb-10'><b>Address : </b>{item.spot_data.address}</IonCardSubtitle>
                                    {(item.status === 'low') ? (<IonBadge slot="start" color='success'>Low</IonBadge>) : (item.status === 'medium') ? (<IonBadge slot="start" color='warning'>Medium</IonBadge>) : (<IonBadge slot="start" color='danger'>High</IonBadge>)}
                                    {/* <IonBadge slot="start" color='success'>Low</IonBadge> */}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                                );
                             })) : <IonRow><IonCol size='12' class='side-heading-1'>
                        <IonText>
                            No Data Found
                        </IonText>
                    </IonCol>
                    </IonRow>
                        }
                        
                       
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ImageHistory;
