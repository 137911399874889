import { IonButton, IonCard, IonCheckbox, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonPopover, IonRow, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonText, IonTitle, IonToggle, IonToolbar, useIonLoading } from '@ionic/react';
import Header from '../../components/header/header';
import './analysis.css';
import LullTime from '../../components/lull-time/lull-time';
import Map from '../../components/map/map';
import { gitCompareOutline, moon, star, sunny } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import React from 'react';
import PoliceLullTime from '../../components/Police-lulltime/Police-lulltime';


const Analysis: React.FC = () => {
    const [present, dismiss] = useIonLoading();
    const [tab, setTab] = useState("lulltime");
    const [date, setDate] = React.useState('');
    const [currentDate, setCurrentData] = React.useState('');
    const [dayMode, setdayMode] = useState(true);
    const [lullTime,setLullTime]=useState([{}])

    let axiosConfig = {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        }
      };


      useEffect(() => {
        fetchTodayData();
        
      }, []);

      let fetchTodayData = ()=>{
        let tempDate = new Date();
        var changedDate = ConvertDate(tempDate);
        setCurrentData(changedDate);
        setDate(changedDate);
        getPoliceChowkyLulltimeData(changedDate);
      }
      let dayNightChange = (e:any) => {
        console.log("this was the event to be set>>",e);
        setdayMode(e);
     }
    


      let fetchValuesFromPicker = (e: any) => {
        // console.log(e);
        let tempDate = new Date(e);
        var changedDate = ConvertDate(tempDate);
        setDate(changedDate);
        getPoliceChowkyLulltimeData(changedDate);
      }
      let ConvertDate = (date: any) => {
        let mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    
      }


      let getPoliceChowkyLulltimeData = (currentDate:any) => {
        console.log(currentDate);
        const payload = { "date": currentDate};
        // present({
        //     message: "Loading...",
        //   });
        axios.post('/getPSLullTimeData', payload, axiosConfig)
          .then(function (response) {
             setLullTime(response.data.lullTime_data);
            dismiss();
            // console.log("this is team data", team);
          })
          .catch(function (error) {
            console.log(error);
            dismiss();
          });
      }


    return (
        <IonPage>
            {/* <IonHeader>
        <IonToolbar>
          <IonTitle>Blank</IonTitle>
        </IonToolbar>
      </IonHeader> */}<Header />
            <IonContent fullscreen id='analysis'>
                <IonRow>
                    <IonCol>
                        <IonCard id='lulltime'>
                            <IonGrid>
                                <IonRow>
                                    <IonCol class='segments' size='8'>
                                        <IonSegment value={tab}>
                                            <IonSegmentButton value="lulltime" onClick={() => setTab("lulltime")}>
                                                <IonLabel>Lull time</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value="Marshalroute">
                                                <IonLabel>Marshal Route</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value="IncidentAnalysis">
                                                <IonLabel>Incident Analysis</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value="OffenceMapping">
                                                <IonLabel>Offence Mapping</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value="EventDiversion">
                                                <IonLabel>Event Diversion</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value="BandobastMapping">
                                                <IonLabel>Bandobast Mapping</IonLabel>
                                            </IonSegmentButton>
                                        </IonSegment>
                                    </IonCol>
                                    <IonCol class='compare'>
                                        <IonButton color='light'>
                                            <IonIcon slot="start" icon={gitCompareOutline} />
                                            Compare
                                        </IonButton>
                                    </IonCol>
                                </IonRow>

                                {tab === 'lulltime' && <>
                                    <IonRow class='map-inputs'>
                                        <IonCol class=''>
                                            <h5>Lull Time</h5>
                                            <p color='light'>Search and if you want to data filter click on filter button.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow class='map-inputs'>
                                    <IonCol class=''>
                                    <IonItem id='open-date-input'>
                                        <IonInput placeholder='Select date' value={date}>
                                        <IonPopover class="open-date-input" trigger="open-date-input">
                                            <IonContent >
                                            <IonDatetime
                                                value={date}
                                                presentation="date"
                                                placeholder='select Date'
                                                hourCycle="h12"
                                                max='2023'
                                                onIonChange={(e) => fetchValuesFromPicker(e.detail.value)}></IonDatetime>

                                            </IonContent >
                                        </IonPopover>

                                        </IonInput>

                                    </IonItem>
                                    </IonCol>
                                        {/* <IonCol class=''>
                                            <IonItem lines='none'>
                                                <IonSelect placeholder='Time Period' value="" okText="Okay" cancelText="Dismiss">
                                                    <IonSelectOption value="brown">Day</IonSelectOption>
                                                    <IonSelectOption value="blonde">Afeternoon</IonSelectOption>
                                                    <IonSelectOption value="black">Night</IonSelectOption>
                                                </IonSelect>
                                            </IonItem>
                                        </IonCol> */}
                                        {/* <IonCol class=''>
                                            <IonItem lines='none' class='checkBox'>
                                                <IonCheckbox color="primary" slot='start' />
                                                <IonLabel>Jurisdiction</IonLabel>
                                            </IonItem>
                                        </IonCol> */}
                                        {/* <IonCol class=''>
                                            <IonItem lines='none' class='checkBox'>
                                                <IonCheckbox color="primary" slot='start' />
                                                <IonLabel>Chowky</IonLabel>
                                            </IonItem>
                                        </IonCol> */}
                                        {/* <IonCol class=''>
                                            <IonItem lines='none' class='checkBox'>
                                                <IonCheckbox color="primary" slot='start' />
                                                <IonLabel>PS</IonLabel>
                                            </IonItem>
                                        </IonCol> */}
                                        {/* <IonCol class=''>
                                            <IonItem lines='none' class='checkBox'>
                                                <IonCheckbox color="primary" slot='start' />
                                                <IonLabel>Zone</IonLabel>
                                            </IonItem>
                                        </IonCol> */}
                                        <IonCol className='toggleDayNight'>
                                            <IonIcon icon={moon}></IonIcon>
                                            <IonToggle onIonChange={(e) => dayNightChange(e.detail.checked) } checked={dayMode}></IonToggle>
                                            <IonIcon color='warning' icon={sunny}></IonIcon>
                                        </IonCol>
                                        <IonCol></IonCol>
                                        <IonCol></IonCol>
                                    </IonRow>
                                </>}

                                {tab === 'Marshalroute' && <>
                                    <IonRow class='map-inputs'>
                                        <IonCol class=''>
                                            <h5>Marshal Route</h5>
                                            <p color='light'>Search and if you want to data filter click on filter button.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow class='map-inputs'>
                                        <IonCol class=''>
                                            <IonItem lines='none'>
                                                <IonInput placeholder='Select Date'></IonInput>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol class=''>
                                            <IonItem lines='none'>
                                                <IonSelect placeholder='Zone' value="" okText="Okay" cancelText="Dismiss">
                                                    <IonSelectOption value="brown">Day</IonSelectOption>
                                                    <IonSelectOption value="blonde">Afeternoon</IonSelectOption>
                                                    <IonSelectOption value="black">Night</IonSelectOption>
                                                </IonSelect>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol class=''>
                                            <IonItem lines='none'>
                                                <IonSelect placeholder='Police Station' value="" okText="Okay" cancelText="Dismiss">
                                                    <IonSelectOption value="brown">Day</IonSelectOption>
                                                    <IonSelectOption value="blonde">Afeternoon</IonSelectOption>
                                                    <IonSelectOption value="black">Night</IonSelectOption>
                                                </IonSelect>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol class=''>
                                            <IonItem lines='none'>
                                                <IonInput placeholder='Search'></IonInput>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol></IonCol>
                                        <IonCol></IonCol>
                                    </IonRow>
                                </>}
                                {tab === 'IncidentAnalysis' && <>
                                    <IonRow class='map-inputs'>
                                        <IonCol class=''>
                                            <h5>Incident Analysis</h5>
                                            <p color='light'>Search and if you want to data filter click on filter button.</p>
                                        </IonCol>
                                    </IonRow></>}
                                {tab === 'OffenceMapping' && <>
                                    <IonRow class='map-inputs'>
                                        <IonCol class=''>
                                            <h5>Offence Mapping</h5>
                                            <p color='light'>Search and if you want to data filter click on filter button.</p>
                                        </IonCol>
                                    </IonRow></>}
                                {tab === 'EventDiversion' && <>
                                    <IonRow class='map-inputs'>
                                        <IonCol class=''>
                                            <h5>Event Diversion</h5>
                                            <p color='light'>Search and if you want to data filter click on filter button.</p>
                                        </IonCol>
                                    </IonRow></>}

                                {tab === 'BandobastMapping' && <>
                                    <IonRow class='map-inputs'>
                                        <IonCol class=''>
                                            <h5>Bandobast Mapping</h5>
                                            <p color='light'>Search and if you want to data filter click on filter button.</p>
                                        </IonCol>
                                    </IonRow></>}

                            </IonGrid>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        {tab === "lulltime" && <><IonCard id='lulltime'>
                            {/* <LullTime /> */}
                            <PoliceLullTime children={lullTime} dayMode={dayMode}/>
                        </IonCard><IonCard id='lulltime'>
                                {/* <LullTime /> */}
                            </IonCard><IonCard id='lulltime'>
                                {/* <LullTime /> */}
                            </IonCard></>
                        }
                        {tab === "Marshalroute" && <IonText>Marshalroute</IonText>}
                        {tab === "IncidentAnalysis" && <IonText>IncidentAnalysis</IonText>}
                        {tab === "OffenceMapping" && <IonText>OffenceMapping</IonText>}
                        {tab === "EventDiversion" && <IonText>EventDiversion</IonText>}
                        {tab === "BandobastMapping" && <IonText>BandobastMapping</IonText>}

                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default Analysis;
