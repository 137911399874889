
import { useState } from 'react';
import {
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonPage, IonRow
} from '@ionic/react';

import Header from '../../components/header/header';
import './row-report.css';
import ReportList from './ReportList/ReportList';
import { Filter } from '../../Filter/Filter';

interface Station {
    subZone: string,
    chowky_data:any[],
    isSelected: string
}

const RowReport: React.FC = () => {
    const [filters, setFilters] = useState({date: '', station:'', chowky: ''})

    const saveFilter = (chowky: string, station: string, date: string) => {
        setFilters({date, station, chowky})
    }
    return (
        <IonPage>
            <Header />
            <IonContent id='rowReport'>
                <IonCard>
                    <IonGrid>
                        <IonRow class='map-inputs'>
                            <IonCol class=''>
                                <h5>Search Here For Details</h5>
                                <p color='light'>Search and if you want to data filter click on filter button.</p>
                            </IonCol>
                        </IonRow>
                        <IonRow class='map-inputs'>
                            <Filter saveFilter={saveFilter}/>
                        </IonRow>
                    </IonGrid>
                </IonCard>
                <ReportList filters={filters}/>
            </IonContent>
        </IonPage>
    );
};

export default RowReport;
