import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonAvatar, IonButton, IonButtons, IonChip, IonHeader, IonIcon, IonItem, IonLabel, IonRouterOutlet, IonSplitPane, IonTitle, IonToolbar, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
// import Home from './pages/Home';
import Login from './pages/login/login';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import Home from './pages/Home';
import Dashboard from './pages/dashboard/dashboard';
import Menu from './components/menu/menu';
import User from './pages/row-report/row-report';
import './App.css';
import Device from './pages/device/device';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import Analysis from './pages/analysis/analysis';
import RowReport from './pages/row-report/row-report';
import Imagegalary from './pages/image-gallary/image-galary';
import Leaflet from './components/leaflet/leaflet';
import { RequireAuth } from 'react-auth-kit';
import {useAuthUser} from 'react-auth-kit'
import ImageHistory from './ImageHistory/ImageHistory';
setupIonicReact();

const App: React.FC = () => {
  const auth = useAuthUser();

  // let isAuthed:boolean;
  // if(window.location.pathname == '/login'){
  //   isAuthed = true;
  // }else isAuthed = false;
  const toggleMenu = () => {
    const splitPane = document.querySelector('ion-split-pane');
    const windowWidth = window.innerWidth;
    const splitPaneShownAt = 992;
    const when = `(min-width: ${splitPaneShownAt}px)`;
    if (windowWidth >= splitPaneShownAt) {
      // split pane view is visible
      const open = (splitPane as HTMLIonSplitPaneElement).when === when;
      (splitPane as HTMLIonSplitPaneElement).when = open ? false : when;
    } else {
      // split pane view is not visible
      // toggle menu open
      const menu = (splitPane as HTMLIonSplitPaneElement).querySelector('ion-menu');
      return (menu as HTMLIonMenuElement).open();
    }
  }
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          {window.location.pathname == "/" ? '' : (window.location.pathname != "/login" && auth()) ? <Menu /> : ''}
          <IonRouterOutlet id="main">
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/login" component={Login} />
            <Route exact path="/analysis" render={()=>auth()?<Analysis/>:<Redirect to="/login" />} />
            <Route exact path="/dashboard" render={()=>auth()?<Dashboard/>: <Redirect to="/login" />} />
            <Route exact path="/leaflet" render={()=>auth()?<Leaflet/>: <Redirect to="/login" />} />
            <Route exact path="/row-report" render={()=>auth()?<RowReport/>:<Redirect to="/login" />} />
            <Route exact path="/image-gallary" render={()=>auth()?<Imagegalary/>:<Redirect to="/login" />} />
            <Route exact path="/image-history" render={()=>auth()?<ImageHistory/>:<Redirect to="/login" />} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
}
export default App;
