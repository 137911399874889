import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonSegment,
  IonNote,
  IonText,
  useIonLoading,
  useIonViewDidLeave,
} from "@ionic/react";
import "./login.css";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useSignIn } from "react-auth-kit";

const Login: React.FC = () => {
  //window.localStorage.removeItem("user_id");
  const [present] = useIonLoading();
  const [mobile_number, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const signin = useSignIn();

  let history = useHistory();
  const submitLogin = () => {
    present({
      message: "Loading...",
      duration: 3000,
    });

    const payload = { mobile_number: mobile_number, password: password };
    fetch(
      "https://dashboard.mysafepune.com/MSPDashboard/index.php/api/MSPuneDashboard/getUserLogin",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then(
        (data) => {
          console.log(data);
          if (data && data != null && data.response_status == 1) {
            signin({
              token:data.user_data.id,
              expiresIn:3600,
              tokenType:"Bearer",
              authState:{mobile_number: mobile_number}
            })
            window.localStorage.setItem("user_id", data.user_data.id);
            history.push({pathname:"/dashboard",
            state:data.user_data.id
          });
          setTimeout(function () {
            window.location.reload();
         }, 500);
          // history.push('/dashboard');
          } else {
            alert("Please enter valid Username and Password.");
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useIonViewDidLeave(() => {
    window.location.reload();
  });

  return (
    <IonPage>
      <IonContent fullscreen id="login">
        <IonGrid className="login-bg-img ion-padding-horizontal">
          <IonRow class="h-100vh">
            <IonCol
              sizeXl="7"
              sizeLg="7"
              sizeMd="7"
              sizeSm="7"
              sizeXs="ion-hide"
              className="ion-hide-md-down"
            >
              <IonRow class="logo-row">
                <IonCol class="logo" size="2">
                  <img
                    src="assets/images/safe-pune-logo.jpeg"
                    alt="profil-img"
                  />
                </IonCol>
                <IonCol>
                  <h3>My</h3>
                  <h1>Safe Pune</h1>
                </IonCol>
              </IonRow>
              <IonRow class="logo-below-text">
                <IonCol>
                  <p>
                    Web Dashboard Access Only <br></br>For Do's & Above.
                  </p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol class="bg-logo" size="12">
                  <img src="assets/images/bg-capture.png" alt="profil-img" />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol
              sizeXl="5"
              sizeLg="5"
              sizeMd="5"
              sizeSm="5"
              sizeXs="12"
              class="m-auto"
            >
              <IonCard className="bg-transparent">
                <IonCardHeader className="ion-margin-top">
                  <IonCardTitle>
                    Hello Again!
                    <IonText>Welcome Back</IonText>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  {/* <IonSegment value='signin' onIonChange={e => console.log('Segment selected', e.detail.value)}>
                                        <IonSegmentButton value="signin">
                                            <IonLabel>Sign In</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="register">
                                            <IonLabel>Register</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment> */}
                  <IonList>
                    <IonItem className="ion-margin-top">
                      <IonLabel position="floating">Username</IonLabel>
                      <IonInput
                        type="text"
                        onIonInput={(e: any) => setMobileNumber(e.target.value)}
                      ></IonInput>
                      <img
                        src="assets/images/userIcon2.png"
                        className="login-icon"
                        alt="profil-img"
                      />
                    </IonItem>
                    <IonItem className="ion-margin-top">
                      <IonLabel position="floating">Password</IonLabel>
                      <IonInput
                        type="password"
                        onIonInput={(e: any) => setPassword(e.target.value)}
                      ></IonInput>
                      <img
                        src="assets/images/lock_1.png"
                        className="login-icon"
                        alt="profil-img"
                      />
                    </IonItem>
                    <IonRow className="ion-padding ion-margin-top">
                      <IonCol>
                        <IonCheckbox class="mr-2"></IonCheckbox>
                        <IonLabel class="mr-2">Remember Me</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top mt-4">
                      <IonCol>
                        <IonButton
                          size="large"
                          className="signin-btn"
                          onClick={() => submitLogin()}
                        >
                          {" "}
                          Login
                          <IonRippleEffect type="unbounded"></IonRippleEffect>
                        </IonButton>
                      </IonCol>
                      <IonCol size="12" className="ion-text-center">
                        <IonLabel>Forgot Password</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
// function ionViewDidEnter(arg0: () => void) {
//     throw new Error('Function not implemented.');
// }
