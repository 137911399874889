import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonPopover,
  IonRippleEffect,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import {
  person,
  locationOutline,
  locationSharp,
  hourglassOutline,
  hourglassSharp,
  timerOutline,
  timerSharp,
  moon,
  sunny,
} from "ionicons/icons";
import Header from "../../components/header/header";
import LullTime from "../../components/lull-time/lull-time";
import Map from "../../components/map/map";
import "./dashboard.css";
import React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";

import Leaflet from "../../components/leaflet/leaflet";



const Dashboard: React.FC = () => {
  const [present, dismiss] = useIonLoading();
  const [selectedChowky, setSelectedChowky] = React.useState({
    chowky: String,
 });
 const [selectedChowkyList, setSelectedChowkyList] = React.useState([{
  chowky: String,
}]);
  const [stations, setStatios] = React.useState([{
    subZone:String,
    chowky_data:[],
    isSelected: 'false'
  }]);
   const [SelectedStation, setSelectedStation] = React.useState([{
    subZone:" ",
    chowky_data:[],
    isSelected: 'false'
  }]);

  const [spot_data, setSpot_data] = React.useState([
    {
      name: String,
      isSelected: 'false'
    }
  ]);
    const [selectedSpot, setSelectedSpot] = React.useState(
      {
          name: String,
          id:"",
      }
  );
  const [team, setTeam] = React.useState([{
    name: String,
    isSelected: 'false'
  }]);

    const [selectedTeam, setSelectedTeam] = React.useState({
      name: String,
      id:""
  });


  const [date, setDate] = React.useState('');
  const [currentDate, setCurrentData] = React.useState('');
  const [dayMode, setdayMode] = useState(true);

  const [lullTime, setLullTime] = React.useState([{}]);
  const [parentToChild, setParentToChild] = React.useState([{}]);
  const handleParentToChild = () => {
    console.log("this was lulltime data",lullTime)
    setParentToChild(lullTime);
  };
  const initalState = [{}];

  // const [lullTime, setLullTime] = React.useState([{}]);
  // const [searchData, setSearchData]=  React.useState([{}]);

  const location = useLocation();
  let history = useHistory();
  // let selectedChowky: any = "";
  // let selectedSpot: any = "";
  // let selectedTeam: any = "";
  let selectedDateInfo: any;
  let search_data: any;
  // const [chowkies,setChowkies] = React.useState([]);
  // const [spot_data,setSpot_data] = React.useState([]);
  // const [team,setTeam]= React.useState([]);
  const [data, setData] = React.useState({
    user_count: Number,
    spot_coverage_count: Number,
    coverage_frequency_count: Number,
    lull_time_slot_count: Number,
    response_message: String,
    response_status: String
  });

  useEffect(() => {
    getActiveCounts();
    getTeamDataDropdown();
    getChowkyDataDropDown();
    getSpotDropdown();
    fetchTodayData()

  }, []);

  useEffect(()=>{
    console.log("this is selected chowky",selectedChowky );
  })





  let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    }
  };

  let fetchTodayData = ()=>{
    let tempDate = new Date();
    var changedDate = ConvertDate(tempDate);
    setCurrentData(changedDate);
    getLullTimeData(changedDate);

  }
  let dayNightChange = (e:any) => {
    console.log("this was the event to be set>>",e);
    setdayMode(e);
 }

  let selectStation = (e: any) => {
    if (e.detail != undefined && e.detail.value != "") {
      // setSelectedChowky(tempVar.chowky);
      // selectedChowky = e.detail.value.chowky;
      // console.log("This is seleted chowky>", selectedChowky);
      // if(e.detail.value){
      //   window.localStorage.setItem("selectedChowky", e.detail.value.chowky);
      // }
      if (stations && (stations.length > 0)) {
        stations.forEach(item => {
          item.isSelected = 'false';
        })
        stations.forEach(item => {
          if (item.subZone === e.detail.value.subZone) {
            item.isSelected = 'true';
            // selectedChowky = e.detail.value.chowky;
            setSelectedStation(e.detail.value);
            setSelectedChowkyList(e.detail.value.chowky_data);
          }
        })
      }


    }

  }


  let selectChowky = (e:any)=>{

    if(selectedChowkyList&& selectedChowkyList.length>0){
      setSelectedChowky(e.detail.value.chowky);
    }

  }

  let selectTeam = (e: any) => {
    // if (e.detail.value != undefined && e.detail.value != "") {
    //   // setSelectedTeam(e.detail.value.name);
    //   selectedTeam = e.detail.value;
    //   console.log("This is seleted team>", selectedTeam);
    //   if(e.detail.value){

    //     // window.localStorage.setItem("selectedTeam", e.detail.value.name);
    //   }
    if (team && (team.length > 0)) {
      team.forEach(item => {
        item.isSelected = 'false';
      })
      team.forEach(item => {
        if (item.name === e.detail.value.name) {
          item.isSelected = 'true';
          setSelectedTeam(e.detail.value);
        }
      })
    }

  }

  let selectSpot = (e: any) => {
    if (e.detail.value != undefined && e.detail.value !== "") {
      // setSelectedSpot(e.detail.value.name);
      // selectedSpot = e.detail.value;
      // if(e.detail.value){
      //   // window.localStorage.setItem("selectedSpot", e.detail.value.name);
      // }
      if (spot_data && (spot_data.length > 0)) {
        spot_data.forEach(item => {
          item.isSelected = 'false';
        })
        spot_data.forEach(item => {
          if (item.name === e.detail.value.name) {
            item.isSelected = 'true';
            setSelectedSpot(e.detail.value);
            // selectedSpot = e.detail.value;
          }
        })
      }



    }
  }


  let fetchValuesFromPicker = (e: any) => {
    // console.log(e);
    let tempDate = new Date(e);
    var changedDate = ConvertDate(tempDate);
    setDate(changedDate);
    getLullTimeData(changedDate);
  }

  let ConvertDate = (date: any) => {
    let mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");

  }

  let getLullTimeData = (selectedDate: any) => {
    const payload = { "date": selectedDate };
    // console.log("this is selected date", selectedDate)
    // present({
    //   message: "Loading...",
    // });
    axios.post('/getLullTimeData', payload, axiosConfig)
      .then(function (response) {
        if (response) {
          if (response.data.lullTime_data) {
            setLullTime({ ...response.data });
          }
          // console.log("this is lull time data>", lullTime);
          // handleParentToChild();
        }
        if(response.data.response_status == "-1" ){
          alert(response.data.response_message);
          setLullTime(initalState);
        }

      })
      .catch(function (error) {
        dismiss();
        console.log(error);
      });
  };


  let getSearchedData = (selectedChowkyData: any, policeStation: any, selectedDate: any, ) => {
    if (selectedChowkyData == undefined) {
      selectedChowkyData = ""
    }
    if (policeStation == undefined) {
      policeStation = ""
    }
    if (selectedDate == undefined) {
      selectedDate = ""
    }
    const payload = { "chowky": selectedChowkyData, "police_station": policeStation.subZone, "date": selectedDate,  };
    present({
      message: "Loading...",
    });
    axios.post('/getSearchedData', payload, axiosConfig)
      .then(function (response) {
        console.log(response);
        search_data = response.data.search_data
         dismiss();  
        history.push({
          pathname: "/dashboard",
          state: search_data
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const payload = { "chowky": selectedChowky, "spot_id": selectedSpot.id, "date": date, "user_id": selectedTeam.name };
  // // present({
  // //   message: "Loading...",
  // // });
  // axios.post('/getSearchedData', payload, axiosConfig)
  //   .then(function (response) {
  //     // setData({...response.data});
  //     // dismiss();  
  //     console.log("this is state", response);
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  // };





  let getActiveCounts = () => {

    const payload = { "user_id": "9" };
    // present({
    //   message: "Loading...",
    // });
    axios.post('/getActiveCounts', payload, axiosConfig)
      .then(function (response) {
        setData({ ...response.data });
        dismiss();
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  let getTeamDataDropdown = () => {
    const payload = { "user_id": "9" };
    // present({
    //     message: "Loading...",
    //   });
    axios.post('/getTeamData', payload, axiosConfig)
      .then(function (response) {
        setTeam(response.data.team_data);
        dismiss();
        // console.log("this is team data", team);
      })
      .catch(function (error) {
        console.log(error);
        dismiss();
      });
  }


  let getChowkyDataDropDown = () => {
    present({
      message: "Loading...",
    });
    axios.post('/getChowkyData', {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: {}
    })
      .then(function (response) {
        console.log("this is chowky data ",response);
        setStatios(response.data.chowky_data);
        dismiss();
      })
      .catch(function (error) {
        console.log(error);
        dismiss();
      });
  }


  let getSpotDropdown = () => {
    const payload = { "user_id": "9" };
    // present({
    //     message: "Loading...",
    //   });
    axios.post('/getSpotDropdown', payload, axiosConfig)
      .then(function (response) {
        //   console.log(response);
        setSpot_data(response.data.spot_data);
        dismiss();
        //   console.log("this is spot data",spot_data);
      })
      .catch(function (error) {
        console.log(error);
        dismiss();
      });
  }
  return (
    <IonPage>
      {/* <IonHeader>
              <IonToolbar>
                  <IonButtons slot="start">
                      <IonButton onClick={() => toggleMenu()}>
                          <IonIcon icon={menu} />
                      </IonButton>
                  </IonButtons>
                  <IonTitle>Dashboard</IonTitle>
              </IonToolbar>
          </IonHeader> */}
      <Header />
      <IonContent fullscreen id="dashboard">
        {/* <IonHeader collapse="condense">
                  <IonToolbar>
                      <IonTitle size="large">Dashboard</IonTitle>
                  </IonToolbar>
              </IonHeader> */}
        {/* <ExploreContainer /> */}
        <IonGrid class="mb-30">
          <IonRow class="dashboard-avatar">
            <IonCol sizeLg="3" sizeMd="3" sizeXl="3" sizeSm="4" sizeXs="6">
              <IonAvatar>
                <IonIcon icon={person} />
              </IonAvatar>
              <IonCard color="warning">
                <IonCardHeader>
                  <IonCardTitle class="ion-text-center">
                    {data.user_count}
                  </IonCardTitle>
                  <IonCardSubtitle class="ion-text-center">
                    Active user
                  </IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="3" sizeMd="3" sizeXl="3" sizeSm="4" sizeXs="6">
              <IonAvatar>
                <IonIcon ios={locationOutline} md={locationSharp}></IonIcon>
              </IonAvatar>
              <IonCard color="warning">
                <IonCardHeader>
                  <IonCardTitle class="ion-text-center">
                    {data.spot_coverage_count}
                  </IonCardTitle>
                  <IonCardSubtitle class="ion-text-center">
                    Spots Covered
                  </IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="3" sizeMd="3" sizeXl="3" sizeSm="4" sizeXs="6">
              <IonAvatar>
                <IonIcon ios={hourglassOutline} md={hourglassSharp}></IonIcon>
              </IonAvatar>
              <IonCard color="warning">
                <IonCardHeader>
                  <IonCardTitle class="ion-text-center">
                    {data.coverage_frequency_count}
                  </IonCardTitle>
                  <IonCardSubtitle class="ion-text-center">
                    Coverage Frequency
                  </IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="3" sizeMd="3" sizeXl="3" sizeSm="4" sizeXs="6">
              <IonAvatar>
                <IonIcon ios={timerOutline} md={timerSharp}></IonIcon>
              </IonAvatar>
              <IonCard color="warning">
                <IonCardHeader>
                  <IonCardTitle class="ion-text-center">
                    {data.lull_time_slot_count}
                  </IonCardTitle>
                  <IonCardSubtitle class="ion-text-center">
                    Lull Time Slots
                  </IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard class='card-h-500'>
                <IonGrid>
                  <IonRow class='map-inputs'>
                    <IonCol sizeLg="3" sizeMd="12" sizeXl="3" sizeSm="12" sizeXs="12">
                      <h5>Live Activity</h5>
                      <p color='light'>(Patrolling | Traffic | Incident | Event)</p>
                    </IonCol>
                    
                    <IonCol class=''>
                      <IonItem>
                        <IonSelect placeholder='Select Police Station' okText="Okay" cancelText="Dismiss" onIonChange={e => selectStation(e)} >
                          {
                            stations.length > 0 ?
                              (stations.map((item, i) => {
                                return (
                                  <IonSelectOption
                                    key={i + 1}
                                    value={item}>
                                    {item.subZone}
                                  </IonSelectOption>
                                );
                              })) : <IonSelectOption value="red">No data Found</IonSelectOption>
                          }
                        </IonSelect>
                      </IonItem>


                    </IonCol>

                    <IonCol class=''>
                      <IonItem>
                        <IonSelect placeholder='Select Chowky' okText="Okay" cancelText="Dismiss" onIonChange={e => selectChowky(e)} >
                          {
                            selectedChowkyList.length > 0 ?
                              (selectedChowkyList.map((item, i) => {
                                return (
                                  <IonSelectOption
                                    key={i + 1}
                                    value={item}>
                                    {item.chowky}
                                  </IonSelectOption>
                                );
                              })) : <IonSelectOption value="red">No data Found</IonSelectOption>
                          }

                        </IonSelect>
                      </IonItem>
                    </IonCol>
                    {/* <IonCol className=''>
                      <IonItem>
                        <IonSelect placeholder='Select Team' okText="Okay" cancelText="Dismiss" onIonChange={e => selectTeam(e)}>
                          {
                            team.length > 0 ?
                              (team.map((item, i) => {
                                return (
                                  <IonSelectOption
                                    key={i + 1}
                                    value={item}>{item.name}</IonSelectOption>
                                );
                              })) : <IonSelectOption value="red">No data Found</IonSelectOption>
                          }

                        </IonSelect>
                      </IonItem>
                    </IonCol> */}
                    {/* <IonCol class=''>
                      <IonItem>
                        <IonSelect placeholder='Select Spot ' okText="Okay" cancelText="Dismiss" onIonChange={e => selectSpot(e)}>
                          {
                            spot_data.length > 0 ?
                              (spot_data.map((item, i) => {
                                return (
                                  <IonSelectOption
                                    key={i + 1}
                                    value={item}>{item.name}</IonSelectOption>
                                );
                              })) : <IonSelectOption value="red">No data Found</IonSelectOption>
                          }
                        </IonSelect>
                      </IonItem>
                    </IonCol> */}

                    <IonCol class='submit-btn'>
                      <IonButton onClick={e => getSearchedData(selectedChowky, SelectedStation, currentDate )}>
                        Submit
                        <IonRippleEffect type="unbounded"></IonRippleEffect>
                      </IonButton>
                      {/* <IonItem>
                      <button onClick={e => getSearchedData(selectedChowky, selectedSpot.id, date, selectedTeam.id)}>Submit</button>
                    </IonItem> */}
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  <IonRow>
                    <Leaflet />
                  </IonRow>
                </IonGrid>
              </IonCard >
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard id="lulltime">
                <IonGrid>
                  <IonRow>
                    <IonCol class="segments" sizeLg="5" sizeMd="5" sizeXl="5" sizeSm="12" sizeXs="12">
                      <IonSegment value="Lulltime">
                        <IonSegmentButton value="Lulltime">
                          <IonLabel>Lull time</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="IncidentAccountability">
                          <IonLabel>Incident Accountability</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="RouteAnalysis">
                          <IonLabel>Route Analysis</IonLabel>
                        </IonSegmentButton>
                      </IonSegment>
                    </IonCol>

                    <IonCol class=''>
                      <IonItem id='open-date-input'>
                        <IonInput placeholder='Select date' value={date}>
                          <IonPopover class="open-date-input" trigger="open-date-input">
                            <IonContent >
                              <IonDatetime
                                value={date}
                                presentation="date"
                                placeholder='select Date'
                                hourCycle="h12"
                                max='2023'
                                onIonChange={(e) => fetchValuesFromPicker(e.detail.value)}></IonDatetime>

                            </IonContent >
                          </IonPopover>

                        </IonInput>

                      </IonItem>
                    </IonCol>

                  </IonRow>
                </IonGrid>
                <LullTime children={lullTime} dayMode={dayMode} />
                <IonRow>
                <IonCol className='toggleDayNight'>
                    {/* <IonChip>
                        <IonAvatar>
                            <img src="https://ionicframework.com/docs/demos/api/avatar/avatar.svg" alt='profile-img' />
                        </IonAvatar>
                        <IonLabel>Day</IonLabel>
                    </IonChip> */}
                    <IonIcon icon={moon}></IonIcon>
                    <IonToggle onIonChange={(e) => dayNightChange(e.detail.checked) } checked={dayMode}></IonToggle>
                    <IonIcon color='warning' icon={sunny}></IonIcon>
                </IonCol>
            </IonRow>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage >
  );
};

export default Dashboard;
