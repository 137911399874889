import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle
} from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { diamondOutline, diamondSharp, homeOutline, homeSharp, keyOutline, keySharp, peopleCircleOutline, peopleCircleSharp } from 'ionicons/icons';
import './menu.css';
import {useSignOut} from "react-auth-kit";


interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  // subtitle:string;
}

const appPages: AppPage[] = [
  {
    title: 'Dashboard',
    // subtitle: 'Dashboards',
    url: '/dashboard',
    iosIcon: homeOutline,
    mdIcon: homeSharp
  },
  {
    title: 'Analysis',
    url: '/analysis',
    // subtitle: 'Accounts',
    iosIcon: keyOutline,
    mdIcon: keySharp
  },
  {
    title: 'Raw Report',
    // subtitle: 'User',
    url: '/row-report',
    iosIcon: peopleCircleOutline,
    mdIcon: peopleCircleSharp
  },
  {
    title: 'Image Gallery',
    // subtitle: 'Devices',
    url: '/image-gallary',
    iosIcon: diamondOutline,
    mdIcon: diamondSharp
  },
  {
    title: 'Leaderboard',
    // subtitle: 'Devices',
    url: '#',
    iosIcon: diamondOutline,
    mdIcon: diamondSharp
  },
  // {
  //   title: 'Admin',
  //   // subtitle: 'Devices',
  //   url: '#', 
  //   iosIcon: diamondOutline,
  //   mdIcon: diamondSharp
  // },
  {
    title: 'Sign Out',
    // subtitle: 'Devices',
    url: '#',
    iosIcon: diamondOutline,
    mdIcon: diamondSharp,
  }
];

//   const labels = ['Family', 'Friends'];

const Menu: React.FC = () => {
  const location = useLocation();
  const signOut = useSignOut();
  


  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonItem lines='none'>
            <img src="assets/images/safe-pune-logo.jpeg" alt='profil-img' />
            <h3>My <br></br>
            Safe Pune</h3>
            {/* <h1>Safe Pune</h1> */}
          </IonItem>
          {appPages.map((appPage, index) => {
            console.log('apppages = ',appPage.title);
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>

                {/* <IonAccordionGroup>
                          <IonAccordion value="{appPage.title}">
                              <IonItem slot="header" className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                                  <IonLabel>{appPage.title}</IonLabel>
                              </IonItem>

                              <IonList slot="content">
                                  <IonItem lines="none" className='ml-55' >
                                      <IonLabel>{appPage.subtitle}</IonLabel>
                                  </IonItem>
                              </IonList> 
                          </IonAccordion>
                          </IonAccordionGroup> */}
              </IonMenuToggle>
            );
          })}
        </IonList>

        {/* <IonList id="labels-list">
            <IonListHeader>Labels</IonListHeader>
            {labels.map((label, index) => (
              <IonItem lines="none" key={index}>
                <IonIcon slot="start" icon={bookmarkOutline} />
                <IonLabel>{label}</IonLabel>
              </IonItem>
            ))}
          </IonList> */}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
