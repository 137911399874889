import {
    IonGrid, IonRow, IonCol,IonList,
    IonListHeader, IonLabel, IonItem
} from '@ionic/react';
import './UsersList.css';
import React from 'react';

export interface UserData {
    id: number
    name: string
    designation: string
    userImage: string
    mobile_number: number
    subZone: string
    chowky: string
    state: string
    fcm_id: string
    shift: string
    password: string
    otp: any
    zone: string
    PS_array: any
    last_update: any
    isSelected: string
}

interface UsersListProps {
    data: any[]
}

const UsersList: React.FC<UsersListProps> = (props) => {

    const {data} = props
    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonList className='user-list'>
                        <IonListHeader lines="inset">
                            <IonLabel class='mx-w-04'>ID</IonLabel>
                            <IonLabel class='mx-w-17'>Names</IonLabel>
                            <IonLabel class='mx-w-11'>Designation</IonLabel>
                            <IonLabel class='mx-w-20'>User Image</IonLabel>
                            <IonLabel class='mx-w-11'>Mobile No.</IonLabel>
                            <IonLabel class='mx-w-11'>Sub Zone</IonLabel>
                            <IonLabel class='mx-w-11'>Chowky</IonLabel>
                        </IonListHeader>
                        { data.length > 0 && data.map((d) => <IonItem>
                            <IonLabel class='mx-w-04'>{d.id}</IonLabel>
                            <IonLabel class='mx-w-17'>{d.name}</IonLabel>
                            <IonLabel class='mx-w-11'>{d.designation}</IonLabel>
                            <IonLabel class='mx-w-20'>{d.userImage}</IonLabel>
                            <IonLabel class='mx-w-11'>{d.mobile_number}</IonLabel>
                            <IonLabel class='mx-w-11'>{d.subZone}</IonLabel>
                            <IonLabel class='mx-w-11'>{d.chowky}</IonLabel>
                        </IonItem>)}
                    </IonList>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default UsersList;
