import {
    AccordionGroupCustomEvent,
    IonAccordion,
    IonAccordionGroup, IonIcon, IonItem, IonLabel,
} from '@ionic/react';
import { downloadOutline, person } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

import UsersList, { UserData } from '../../../components/UsersList/UsersList';
import './ReportList.css';
import SpotsList, { SpotsData } from '../../../components/SpotsList/SpotsList';
import SpotsHistory, { SpotsHistoryData }  from '../../../components/SpotHistory/SpotHistory';
import LullTimePS, { LullTimePSData } from '../../../components/LullTimePS/LullTimePS';
import LullTime, { LullTimeData} from '../../../components/LullTime/LullTime';
import LullTimePC, { LullTimePCData } from '../../../components/ImageReported/LullTimePC';

export interface Filters {
    chowky: string
    station: string
    date: string
}

interface Props {
    filters: Filters
}

const ReportList = (props: Props) => {
    let axiosConfig = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    }
  };

    const [users, setUsers] = useState<UserData[]>([])
    const [spots, setSpots] = useState<SpotsData[]>([])
    const [spotsHistory, setSpotsHistory] = useState<SpotsHistoryData[]>([])
    const [lullTimePS, setLullTimePS] = useState<LullTimePSData[]>([])
    const [lullTime, setLullTime] = useState<LullTimeData[]>([])
    const [lullTimePC, setLullTimePC] = useState<LullTimePCData[]>([])
    const [selectedList, setSelectedList] = useState('')

    useEffect(()=> {
        getList()
    },[props.filters])

    let exportToExcel = (event:any, excelData: any, fileName: string) => {
        event?.stopPropagation()
        event.nativeEvent.stopImmediatePropagation();
        if(excelData.length > 0) {
            const fileType ='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExt = '.xlsx'
            const ws = XLSX.utils.json_to_sheet(excelData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName + fileExt);
        }
    }

    let getUsersList = () => {
        const payload = {"police_chowky": props.filters.chowky, "police_station": props.filters.station, "date": props.filters.date, "user_id":localStorage.getItem('user_id')};
        axios.post('/getRawReportUserList', payload, axiosConfig)
          .then((response) => {
              if (response?.data?.user_data) {
                let user_data: UserData[] = response?.data?.user_data
                setUsers(user_data);
              } 
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      let getSpotList = () => {
        const payload = {"police_chowky": props.filters.chowky, "police_station": props.filters.station, "date": props.filters.date, "user_id":localStorage.getItem('user_id')};
        axios.post('/getRawReportSpotList', payload, axiosConfig)
          .then((response) => {
              if (response?.data?.spot_data) {
                let spot_data: SpotsData[] = response?.data?.spot_data
                setSpots(spot_data);
              } 
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      let getSpotHistoryList = () => {
        const payload = {"police_chowky": props.filters.chowky, "police_station": props.filters.station, "date": props.filters.date, "user_id":localStorage.getItem('user_id')};
        axios.post('/getRawReportSpotHistory', payload, axiosConfig)
          .then((response) => {
              if (response?.data?.spot_history_data) {
                let spot_history_data: SpotsHistoryData[] = response?.data?.spot_history_data
                setSpotsHistory(spot_history_data);
              } 
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      let getLullTimePSList = () => {
        const payload = {"police_chowky": props.filters.chowky, "police_station": props.filters.station, "date": props.filters.date, "user_id":localStorage.getItem('user_id')};
        axios.post('/getRawReportLullTimePSList', payload, axiosConfig)
          .then((response) => {
              if (response?.data?.lull_time_PS_data) {
                let lull_time_PS_data: LullTimePSData[] = response?.data?.lull_time_PS_data
                setLullTimePS(lull_time_PS_data);
              } 
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      let getLullTimePCList = () => {
        const payload = {"police_chowky": props.filters.chowky, "police_station": props.filters.station, "date": props.filters.date, "user_id":localStorage.getItem('user_id')};
        axios.post('/getRawReportLullTimePCList', payload, axiosConfig)
          .then((response) => {
              if (response?.data?.lull_time_pc_data) {
                let lull_time_pc_data: LullTimePCData[] = response?.data?.lull_time_pc_data
                setLullTimePC(lull_time_pc_data);
              } 
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      let getLullTimeList = () => {
        const payload = {"police_chowky": props.filters.chowky, "police_station": props.filters.station, "date": props.filters.date, "user_id":localStorage.getItem('user_id')};
        axios.post('/getRawReportLullTimeList', payload, axiosConfig)
          .then((response) => {
              if (response?.data?.lullTime_data) {
                let lullTime_data: LullTimeData[] = response?.data?.lullTime_data
                setLullTime(lullTime_data);
              } 
          })
          .catch(function (error) {
            console.log(error);
          });
      };

    const handleListChange = (ev: AccordionGroupCustomEvent) => {
        if(!props.filters.chowky && !props.filters.date && !props.filters.station) {
            alert('Please Select & Submit Filter !!')
            ev.preventDefault()
            // ev.stopPropagation()
            // ev.stopImmediatePropagation()
        } else {
            const selectedValue = ev.detail.value;
            setSelectedList(selectedValue)
            getList(selectedValue ?? '-1')
        }
    }
    const getList = (selectedValue?: any) => {
            let val = selectedValue ?? selectedList
            if (val === "first") {
                getUsersList()
            } else if(val === "second") {
                getSpotList();
            } else if(val === "third") {
                getSpotHistoryList();
            } else if(val === "forth") {
                getLullTimeList();
            } else if(val === "fifth") {
                getLullTimePSList();
            } else {
                getLullTimePCList();
            }
    }
    return (
            <IonAccordionGroup onIonChange={handleListChange}>
                <IonAccordion value="first">
                    <IonItem slot="header" color="white" lines='none'>
                        <IonIcon class='person-icon' icon={person} color='light'></IonIcon>
                        <IonLabel>List of User</IonLabel>
                        <IonIcon icon={downloadOutline} onClick={(event) => exportToExcel(event,users, 'users')}></IonIcon>
                    </IonItem>
                    <div slot="content">
                        <UsersList data={users}></UsersList>
                    </div>
                </IonAccordion>
                <IonAccordion value="second">
                    <IonItem slot="header" color="white" lines='none'>
                        <IonIcon class='person-icon' icon={person} color='light'></IonIcon>
                        <IonLabel>List of Spot</IonLabel>
                        <IonIcon icon={downloadOutline} onClick={(event) => exportToExcel(event,spots, 'spots')}></IonIcon>
                    </IonItem>
                    <div slot="content">
                        <SpotsList data={spots}></SpotsList>
                    </div>
                </IonAccordion>
                <IonAccordion value="third">
                    <IonItem slot="header" color="white" lines='none'>
                        <IonIcon class='person-icon' icon={person} color='light'></IonIcon>
                        <IonLabel>Spot History</IonLabel>
                        <IonIcon icon={downloadOutline} onClick={(event) => exportToExcel(event,spotsHistory, 'spotsHistory')}></IonIcon>
                    </IonItem>
                    <div slot="content">
                        <SpotsHistory data={spotsHistory}></SpotsHistory>
                    </div>
                </IonAccordion>
                <IonAccordion value="forth">
                    <IonItem slot="header" color="white" lines='none'>
                        <IonIcon class='person-icon' icon={person} color='light'></IonIcon>
                        <IonLabel>Lull Time</IonLabel>
                        <IonIcon icon={downloadOutline} onClick={(event) => exportToExcel(event,lullTime, 'lullTime')}></IonIcon>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                        <LullTime data={lullTime}></LullTime>
                    </div>
                </IonAccordion>
                <IonAccordion value="fifth">
                    <IonItem slot="header" color="white" lines='none'>
                        <IonIcon class='person-icon' icon={person} color='light'></IonIcon>
                        <IonLabel>Lull Time PS</IonLabel>
                        <IonIcon icon={downloadOutline} onClick={(event) => exportToExcel(event,lullTimePS, 'lullTimePS')}></IonIcon>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                        <LullTimePS data={lullTimePS}></LullTimePS>
                    </div>
                </IonAccordion>
                <IonAccordion value="sixth">
                    <IonItem slot="header" color="white" lines='none'>
                        <IonIcon class='person-icon' icon={person} color='light'></IonIcon>
                        <IonLabel>Lull Time PC</IonLabel>
                        <IonIcon icon={downloadOutline} onClick={(event) => exportToExcel(event,lullTimePC, 'lullTimePC')}></IonIcon>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                        <LullTimePC data={lullTimePC}></LullTimePC>
                    </div>
                </IonAccordion>
            </IonAccordionGroup>
    );
};

export default ReportList;
