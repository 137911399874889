import { IonButton, IonCol, IonContent, IonDatetime, IonInput, IonItem, IonPopover, IonRippleEffect, IonSelect, IonSelectOption, useIonLoading } from "@ionic/react";
import axios from "axios";
import React, { useEffect } from "react";

interface Station {
    subZone: string,
    chowky_data:any[],
    isSelected: string
}

interface Props {
    saveFilter : (chowky: string, station: string, date: string) => void
}

export const Filter = (props: Props) => {
    const [present, dismiss] = useIonLoading();
    const [date, setDate]= React.useState('');
    const [selectedChowkyList, setSelectedChowkyList] = React.useState([{
        chowky: '',
      }]);
      const [selectedChowky, setSelectedChowky] = React.useState('');
      const [selectedStation, setSelectedStation] = React.useState('');
    const [stations, setStatios] = React.useState([{
        subZone:'',
        chowky_data:[],
        isSelected: 'false'
      }]);
    
    let getChowkyDataDropDown = () => {
        present({
            message: "Loading...",
        });
        axios.post('/getChowkyData', {
            headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            },
            body: {}
        })
        .then(function (response) {
            setStatios(response.data.chowky_data);
            dismiss();
        })
        .catch(function (error) {
            console.log(error);
            dismiss();
        });
    }
    let fetchValuesFromPicker = (e:any)=>{
        let tempDate = new Date(e);
        var changedDate = ConvertDate(tempDate);
        setDate(changedDate);
       // getLullTimeData(changedDate);
    }

    let ConvertDate = (date:any)=>{
        let mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");

    }
    let selectStation = (e: any) => {
        if (e.detail !== undefined && e.detail.value !== "") {
          if (stations && (stations.length > 0)) {
            stations.forEach(item => {
              item.isSelected = 'false';
            })
            stations.forEach(item => {
              if (item.subZone === e.detail.value.subZone) {
                item.isSelected = 'true';
                setSelectedStation(e.detail.value.subZone);
                setSelectedChowkyList(e.detail.value.chowky_data);
              }
            })
          }
        }
      }
    let selectChowky = (e:any)=>{
        if(selectedChowkyList&& selectedChowkyList.length>0){
            setSelectedChowky(e.detail.value.chowky);
        }
    }
    useEffect(() => {
        getChowkyDataDropDown();
        var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        fetchValuesFromPicker(date);
      }, []);
    return (
        <>        
    <IonCol class=''>
                        <IonItem id='open-date-input'>
                            <IonInput placeholder='Select date' value={date}>
                            <IonPopover class="open-date-input" trigger="open-date-input">
                                <IonContent >
                                    <IonDatetime
                                    value={date}
                                    presentation= "date"
                                    placeholder='select Date'
                                    hourCycle="h12"
                                    max='2023'
                                    onIonChange={(e)=>fetchValuesFromPicker(e.detail.value)}></IonDatetime>
                                </IonContent >
                            </IonPopover>
                            </IonInput>  
                        </IonItem>
                    </IonCol>
                           <IonCol class=''>
                      <IonItem>
                        <IonSelect placeholder='Select Police Station' okText="Okay" cancelText="Dismiss" onIonChange={e => selectStation(e)} >
                          {
                            stations.length > 0 ?
                              (stations.map((item, i) => {
                                return (
                                  <IonSelectOption
                                    key={i + 1}
                                    value={item}>
                                    {item.subZone}
                                  </IonSelectOption>
                                );
                              })) : <IonSelectOption value="red">No data Found</IonSelectOption>
                          }
                        </IonSelect>
                      </IonItem>
                    </IonCol>
                            <IonCol class=''>
                      <IonItem>
                        <IonSelect placeholder='Select Chowky' okText="Okay" cancelText="Dismiss" onIonChange={e => selectChowky(e)} >
                          {
                            selectedChowkyList.length > 0 ?
                              (selectedChowkyList.map((item, i) => {
                                return (
                                  <IonSelectOption
                                    key={i + 1}
                                    value={item}>
                                    {item.chowky}
                                  </IonSelectOption>
                                );
                              })) : <IonSelectOption value="red">No data Found</IonSelectOption>
                          }

                        </IonSelect>
                      </IonItem>
                    </IonCol>
                    <IonCol class='submit-btn'>
                      <IonButton onClick={e => props.saveFilter(selectedChowky, selectedStation, date )}>
                        Save Filter
                        <IonRippleEffect type="unbounded"></IonRippleEffect>
                      </IonButton>
                    </IonCol>
        </>
    )
}