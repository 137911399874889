import { IonAvatar, IonBadge, IonButton, IonButtons, IonCard, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { heart, home, menu, pin, sunny, moon } from 'ionicons/icons';
import { ReactChild, ReactFragment, ReactPortal, useState } from 'react';
// import ExploreContainer from '../components/ExploreContainer';
import './lull-time.css';

interface children {
    children: any;
    dayMode: any;
}

const LullTime: React.FC<children> = ({ children, dayMode }) => {
    // const [dayMode, setdayMode] = useState(true);
    let lullTime: any = children;
    let lullData = lullTime.lullTime_data;
    console.log('Lull on lull compo', lullData);

    //  let dayMode:boolean = true;
    // let dayNightChange = (e:any) => {
    //      setdayMode(e);
    //   }

    return (
        <IonGrid>
            {dayMode ? (<IonRow>
                <IonCol size='2' class='side-heading-0'>
                    <IonText>{window.location.pathname === '/analysis' ? 'Police Station' : 'PS Zone'}</IonText>
                    <p><small>{window.location.pathname === '/analysis' ? '(Zone 1)' : ''}</small></p>
                </IonCol>
                <IonCol size='10'>
                    <IonRow>
                        <IonCol>12 AM-<br></br> 01 AM</IonCol>
                        <IonCol>01 AM-<br></br> 02 AM</IonCol>
                        <IonCol>02 AM-<br></br> 03 AM</IonCol>
                        <IonCol>03 AM-<br></br> 04 AM</IonCol>
                        <IonCol>04 AM-<br></br> 05 AM</IonCol>
                        <IonCol>05 AM-<br></br> 06 AM</IonCol>
                        <IonCol>06 AM-<br></br> 07 AM</IonCol>
                        <IonCol>07 AM-<br></br> 08 AM</IonCol>
                        <IonCol>08 AM-<br></br> 09 AM</IonCol>
                        <IonCol>09 AM-<br></br> 10 AM</IonCol>
                        <IonCol>10 AM-<br></br> 11 AM</IonCol>
                        <IonCol>11 AM-<br></br> 12 PM</IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>): (<IonRow>
                <IonCol size='2' class='side-heading-0'>
                    <IonText>{window.location.pathname === '/analysis' ? 'Police Station' : 'PS Zone'}</IonText>
                    <p><small>{window.location.pathname === '/analysis' ? '(Zone 1)' : ''}</small></p>
                </IonCol>
                <IonCol size='10'>
                    <IonRow>
                        <IonCol>12 PM-<br></br> 01 PM</IonCol>
                        <IonCol>01 PM-<br></br> 02 PM</IonCol>
                        <IonCol>02 PM-<br></br> 03 PM</IonCol>
                        <IonCol>03 PM-<br></br> 04 PM</IonCol>
                        <IonCol>04 PM-<br></br> 05 PM</IonCol>
                        <IonCol>05 PM-<br></br> 06 PM</IonCol>
                        <IonCol>06 PM-<br></br> 07 PM</IonCol>
                        <IonCol>07 PM-<br></br> 08 PM</IonCol>
                        <IonCol>08 PM-<br></br> 09 PM</IonCol>
                        <IonCol>09 PM-<br></br> 10 PM</IonCol>
                        <IonCol>10 PM-<br></br> 11 PM</IonCol>
                        <IonCol>11 PM-<br></br> 12 AM</IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>)}
            
            {
                lullData && lullData.length > 0 ?
                    (lullData.map((item: any, idx: any) => {
                        return (
                            (dayMode) ? (
                                <IonRow key={idx}>
                                    <IonCol size='2' class='side-heading-1'>
                                        <IonText>
                                            {item.zone}
                                        </IonText>
                                    </IonCol><IonCol size='10'>
                                        <IonRow class='zone-time'>
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value1 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value1 >= 12 && item.dayObject[0].value1 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value1.toString()}> {item.dayObject[0].value1}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value1 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value1 >= 14 && item.dayObject[0].value1 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value1.toString()}> {item.dayObject[0].value1}</IonBadge></IonCol>)}
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value2 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value2 >= 12 && item.dayObject[0].value2 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value2.toString()}> {item.dayObject[0].value2}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value2 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value2 >= 14 && item.dayObject[0].value2 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value2.toString()}> {item.dayObject[0].value2}</IonBadge></IonCol>)}
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value3 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value3 >= 12 && item.dayObject[0].value3 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value3.toString()}> {item.dayObject[0].value3}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value3 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value3 >= 14 && item.dayObject[0].value3 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value3.toString()}> {item.dayObject[0].value3}</IonBadge></IonCol>)}
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value4 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value4 >= 12 && item.dayObject[0].value4 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value4.toString()}> {item.dayObject[0].value4}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value4 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value4 >= 14 && item.dayObject[0].value4 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value4.toString()}> {item.dayObject[0].value4}</IonBadge></IonCol>)}
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value5 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value5 >= 12 && item.dayObject[0].value5 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value5.toString()}> {item.dayObject[0].value5}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value5 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value5 >= 14 && item.dayObject[0].value5 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value5.toString()}> {item.dayObject[0].value5}</IonBadge></IonCol>)}
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value6 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value6 >= 12 && item.dayObject[0].value6 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value6.toString()}> {item.dayObject[0].value6}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value6 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value6 >= 14 && item.dayObject[0].value6 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value6.toString()}> {item.dayObject[0].value6}</IonBadge></IonCol>)}
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value7 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value7 >= 12 && item.dayObject[0].value7 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value7.toString()}> {item.dayObject[0].value7}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value7 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value7 >= 14 && item.dayObject[0].value7 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value7.toString()}> {item.dayObject[0].value7}</IonBadge></IonCol>)}
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value8 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value8 >= 12 && item.dayObject[0].value8 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value8.toString()}> {item.dayObject[0].value8}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value8 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value8 >= 14 && item.dayObject[0].value8 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value8.toString()}> {item.dayObject[0].value8}</IonBadge></IonCol>)}
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value9 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value9 >= 12 && item.dayObject[0].value9 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value9.toString()}> {item.dayObject[0].value9}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value9 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value9 >= 14 && item.dayObject[0].value9 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value9.toString()}> {item.dayObject[0].value9}</IonBadge></IonCol>)}
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value10 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value10 >= 12 && item.dayObject[0].value10 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value10.toString()}> {item.dayObject[0].value10}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value10 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value10 >= 14 && item.dayObject[0].value10 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value10.toString()}> {item.dayObject[0].value10}</IonBadge></IonCol>)}
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value11 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value11 >= 12 && item.dayObject[0].value11 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value11.toString()}> {item.dayObject[0].value11}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value11 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value11 >= 14 && item.dayObject[0].value11 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value11.toString()}> {item.dayObject[0].value11}</IonBadge></IonCol>)}
                                            {(item.zone === 'ZONE 1' || item.zone === 'ZONE 2' || item.zone === 'ZONE 3') ? (<IonCol><IonBadge style={item.dayObject[0].value12 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value12 >= 12 && item.dayObject[0].value12 <= 30) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value12.toString()}> {item.dayObject[0].value12}</IonBadge></IonCol>) : (<IonCol><IonBadge style={item.dayObject[0].value12 > 35 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value12 >= 14 && item.dayObject[0].value12 <= 35) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value12.toString()}> {item.dayObject[0].value12}</IonBadge></IonCol>)}
                                            {/* <IonCol> <IonBadge style={item.dayObject[0].value2 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value2 < 15 && item.dayObject[0].value2 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value2.toString()}>{item.dayObject[0].value2}</IonBadge></IonCol>
                                            <IonCol><IonBadge style={item.dayObject[0].value3 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value3 < 15 && item.dayObject[0].value3 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value3.toString()}>{item.dayObject[0].value3}</IonBadge></IonCol>
                                            <IonCol> <IonBadge style={item.dayObject[0].value4 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value4 < 15 && item.dayObject[0].value4 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value4.toString()}>{item.dayObject[0].value4}</IonBadge></IonCol>
                                            <IonCol><IonBadge style={item.dayObject[0].value5 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value5 < 15 && item.dayObject[0].value5 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value5.toString()}>{item.dayObject[0].value5}</IonBadge></IonCol>
                                            <IonCol> <IonBadge style={item.dayObject[0].value6 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value6 < 15 && item.dayObject[0].value6 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value6.toString()}>{item.dayObject[0].value6}</IonBadge></IonCol>
                                            <IonCol><IonBadge style={item.dayObject[0].value7 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value7 < 15 && item.dayObject[0].value7 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value7.toString()}>{item.dayObject[0].value7}</IonBadge></IonCol>
                                            <IonCol> <IonBadge style={item.dayObject[0].value8 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value8 < 15 && item.dayObject[0].value8 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value8.toString()}>{item.dayObject[0].value8}</IonBadge></IonCol>
                                            <IonCol><IonBadge style={item.dayObject[0].value9 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value9 < 15 && item.dayObject[0].value9 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value9.toString()}>{item.dayObject[0].value9}</IonBadge></IonCol>
                                            <IonCol> <IonBadge style={item.dayObject[0].value10 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value10 < 15 && item.dayObject[0].value10 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value10.toString()}>{item.dayObject[0].value10}</IonBadge></IonCol>
                                            <IonCol><IonBadge style={item.dayObject[0].value11 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value11 < 15 && item.dayObject[0].value11 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value11.toString()}>{item.dayObject[0].value11}</IonBadge></IonCol>
                                            <IonCol> <IonBadge style={item.dayObject[0].value12 > 30 ? { backgroundColor: '#5cb85c' } : (item.dayObject[0].value12 < 15 && item.dayObject[0].value12 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.dayObject[0].value12.toString()}>{item.dayObject[0].value12}</IonBadge></IonCol> */}
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                            ) : (
                                <IonRow key={item.id}>
                                    <IonCol size='2' class='side-heading-1'>
                                        <IonText>
                                            {item.zone}
                                        </IonText>
                                    </IonCol><IonCol size='10'>
                                        <IonRow class='zone-time'>
                                            <IonCol><IonBadge style={item.nightObject[0].value13 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value13 < 15 && item.nightObject[0].value13 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value13.toString()}>{item.nightObject[0].value13}</IonBadge></IonCol>
                                            <IonCol> <IonBadge style={item.nightObject[0].value14 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value14 < 15 && item.nightObject[0].value14 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value14.toString()}>{item.nightObject[0].value14}</IonBadge></IonCol>
                                            <IonCol><IonBadge style={item.nightObject[0].value15 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value15 < 15 && item.nightObject[0].value15 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value15.toString()}>{item.nightObject[0].value15}</IonBadge></IonCol>
                                            <IonCol> <IonBadge style={item.nightObject[0].value16 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value16 < 15 && item.nightObject[0].value16 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value16.toString()}>{item.nightObject[0].value16}</IonBadge></IonCol>
                                            <IonCol><IonBadge style={item.nightObject[0].value17 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value17 < 15 && item.nightObject[0].value17 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value17.toString()}>{item.nightObject[0].value17}</IonBadge></IonCol>
                                            <IonCol> <IonBadge style={item.nightObject[0].value18 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value18 < 15 && item.nightObject[0].value18 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value18.toString()}>{item.nightObject[0].value18}</IonBadge></IonCol>
                                            <IonCol><IonBadge style={item.nightObject[0].value19 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value19 < 15 && item.nightObject[0].value19 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value19.toString()}>{item.nightObject[0].value19}</IonBadge></IonCol>
                                            <IonCol> <IonBadge style={item.nightObject[0].value20 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value20 < 15 && item.nightObject[0].value20 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value20.toString()}>{item.nightObject[0].value20}</IonBadge></IonCol>
                                            <IonCol><IonBadge style={item.nightObject[0].value21 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value21 < 15 && item.nightObject[0].value21 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value21.toString()}>{item.nightObject[0].value21}</IonBadge></IonCol>
                                            <IonCol> <IonBadge style={item.nightObject[0].value22 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value22 < 15 && item.nightObject[0].value22 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value22.toString()}>{item.nightObject[0].value22}</IonBadge></IonCol>
                                            <IonCol><IonBadge style={item.nightObject[0].value23 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value23 < 15 && item.nightObject[0].value23 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value23.toString()}>{item.nightObject[0].value23}</IonBadge></IonCol>
                                            <IonCol> <IonBadge style={item.nightObject[0].value24 > 30 ? { backgroundColor: '#5cb85c' } : (item.nightObject[0].value24 < 15 && item.nightObject[0].value24 >= 12) ? { backgroundColor: 'orange' } : { backgroundColor: 'red' }} key={item.nightObject[0].value24.toString()}>{item.nightObject[0].value24}</IonBadge></IonCol>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>

                            )

                        );
                    })) : <IonRow><IonCol size='12' class='side-heading-1'>
                        <IonText>
                            No Data Found
                        </IonText>
                    </IonCol>
                    </IonRow>
            }

        </IonGrid>
    );
};

export default LullTime;
