import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import { useLocation } from 'react-router';
import L from "leaflet";
import '../../App.css';
import { useHistory } from "react-router-dom";



const noramlMarkerIcon = new L.Icon({
  iconUrl:require("../../Resources/Ellipse 2385.png"),
  iconSize:[20,20],
})
const lowMarkerIcon = new L.Icon({
  iconUrl:require("../../Resources/Ellipse 2382.png"),
  iconSize:[20,20],
})
const mediumMarkerIcon = new L.Icon({
  iconUrl:require("../../Resources/Ellipse 2383.png"),
  iconSize:[20,20],
})
const highMarkerIcon = new L.Icon({
  iconUrl:require("../../Resources/Ellipse 2384.png"),
  iconSize:[20,20],
})


const normalPriorityMarkerIcon = new L.Icon({
  iconUrl:require("../../Resources/Vector-2.png"),
  iconSize:[27,27]
})
const lowPriorityMarkerIcon = new L.Icon({
  iconUrl:require("../../Resources/Vector.png"),
  iconSize:[27,27]
})
const mediumPriorityMarkerIcon = new L.Icon({
  iconUrl:require("../../Resources/Vector-3.png"),
  iconSize:[27,27]
})
const highPriorityMarkerIcon = new L.Icon({
  iconUrl:require("../../Resources/Vector-1.png"),
  iconSize:[27,27]
})



const Leaflet: React.FC= () => {
  let mapData :any;
  
   let history = useHistory();
  const location = useLocation();
  const URl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  const [center, setCenter] = useState({ lat: 18.5204, lng: 73.8567 });
  const [ZoomLevel ,setZoom] = useState(9);
  const [MapInfo, setMapInfo]= useState([
    
      {
          "id": "1251",
          "name": "ganpati chouk mujabawasti",
          "defaultImage": "https://mysafepune.com/MSPune/uploads/SpotHistory/1637751910-1637751905565.jpg",
          "video_image": "",
          "address": "15, Munjaba Wasti, Dhanori, Pune, Maharashtra 411015, India",
          "locality": "null",
          "latitude": 18.59204958223253,
          "longitude": 73.89654882252216,
          "existing_new": "new",
          "added_by": "No data found",
          "assignedUserIdArray": "[297]",
          "assigned_PS": "VISHRANTWADI POLICE STATION",
          "assigned_PC": "DHANORI",
          "date": "No data found",
          "category": "general",
          "spot_status": "normal",
          "comment": "",
          "delete_spot": null,
          "delete_requested_by_id": null,
          "zone": "ZONE4",
          "spot_category": null,
          "spot_sub_category": null,
          "datetime": null,
          "last_update": "No data found",
          "status": "",
          "comments": "No data found",
          "history_data": []
      },
    //   {
    //     "id": "1251",
    //     "name": "ganpati chouk mujabawasti",
    //     "defaultImage": "https://mysafepune.com/MSPune/uploads/SpotHistory/1637751910-1637751905565.jpg",
    //     "video_image": "",
    //     "address": "15, Munjaba Wasti, Dhanori, Pune, Maharashtra 411015, India",
    //     "locality": "null",
    //     "latitude": 19.59204958223253,
    //     "longitude": 78.89654882252216,
    //     "existing_new": "new",
    //     "added_by": "No data found",
    //     "assignedUserIdArray": "[297]",
    //     "assigned_PS": "VISHRANTWADI POLICE STATION",
    //     "assigned_PC": "DHANORI",
    //     "date": "No data found",
    //     "category": "general",
    //     "spot_status": "normal",
    //     "comment": "",
    //     "delete_spot": null,
    //     "delete_requested_by_id": null,
    //     "zone": "ZONE4",
    //     "spot_category": null,
    //     "spot_sub_category": null,
    //     "datetime": null,
    //     "last_update": "No data found",
    //     "status": "",
    //     "comments": "No data found",
    //     "history_data": []
    // },
  ]);
  // let ZoomLevel = 9;
    if(location.state){
    mapData = location.state;
  }

  useEffect(()=>{
    if((mapData !== 9)){
      setMapInfo(mapData); 
      setCenter({ lat: 18.5204, lng: 73.8567 });
      setZoom(3);
    }
  },[mapData])
  
  setTimeout(function () {
    window.dispatchEvent(new Event("resize"));
    // console.log("this was map data",mapData);
    // console.log("this was map info",MapInfo);
 }, 500);

 const redirectToHistoryPage = useCallback(
    (id) => () => {
      history.push({pathname:"/image-history",
            state: id
          });
    },
    []
  );


  return (
    <Fragment>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <MapContainer
          center={center}
          zoom={ZoomLevel}
          style={{ width: "100vw", height: "600px" }}
        >
          <TileLayer url={URl}  />
{/* 
          <Marker position={[18.586060614720925,73.89604020863773]} 
          icon={priorityMarkerIcon}>
          <Popup>
            <b>second marker</b>
          </Popup>
          </Marker> */}
        
           {
            (MapInfo && (MapInfo.length>0) && MapInfo[0].latitude && MapInfo[0].longitude ) ?
              MapInfo.map((item:any,idx:any)=>
                  {
               return (item.category === "general" && item.status === " ") ?(
               
               <Marker position={[item.latitude,item.longitude]} 
                  icon={noramlMarkerIcon}
                  key= {idx}
                  >   
                  <Popup>
                    <div >
                      <div style={{width:"300px",height:'150px'}}>
                      <img src={item.defaultImage} style={{backgroundSize:'cover',width:"300px",height:'150px'}} />
                      </div>
                    
                    <div>
                    <p style={{fontWeight:"700"}}>Spot Name - {item.name}</p>
                      <p>{item.address}</p>
                    </div>
                      
                    {/* <div>
                      <p style={{fontWeight:"700",color:"grey"}}>{item.locality}</p>
                    </div> */}
                    <div>
                      <p style={{fontWeight:"700"}}>No Spot Covered today</p>
                    </div>
                    
                    </div>
                    
                  </Popup>
                  </Marker>):
                  (item.category === "general" && item.status === "low")?(
                    <Marker position={[item.latitude,item.longitude]} 
                    icon={lowMarkerIcon}
                    key= {idx}
                    >   
                    <Popup>
                      <div >
                        <div style={{width:"300px",height:'150px'}}>
                        <img src={item.history_data[0].imagePath} style={{backgroundSize:'cover',width:"300px",height:'150px'}} />
                        </div>
                      <div style={{width:'250px'}}>
                        <p style={{fontWeight:"700"}}>Spot Name - {item.name}</p>
                         <p> {item.address}</p>
                      </div>
                      
                      {/* <div>
                      <p style={{fontWeight:"700"}}>Date</p>
                        <p>{item.last_update}</p>
                      </div> */}
                        
                      {/* <div>
                        <p style={{fontWeight:"700",color:"grey"}}>{item.locality}</p>
                      </div> */}
                      <div>
                        <p style={{fontWeight:"700"}}>Date - {item.history_data[0].last_update}</p>
                      </div>

                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p style={{backgroundColor: '#4CAF50', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}}>Low</p>
                            <button style={{backgroundColor: '#008CBA', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}} onClick = {redirectToHistoryPage(item.id)}>History</button>
                      </div>
                      
                      </div>
                      
                    </Popup>
                    </Marker>
                  ):
                  (item.category === "general" && item.status === "medium")?(
                    <Marker position={[item.latitude,item.longitude]} 
                    icon={mediumMarkerIcon}
                    key= {idx}
                    >   
                    <Popup>
                      <div >
                        <div style={{width:"300px",height:'150px'}}>
                        <img src={item.history_data[0].imagePath} style={{backgroundSize:'cover',width:"300px",height:'150px'}} />
                        </div>
                      
                      <div>
                      <p style={{fontWeight:"700"}}>Spot Name - {item.name}</p>
                      </div>
                        
                      {/* <div>
                        <p style={{fontWeight:"700",color:"grey"}}>{item.locality}</p>
                      </div> */}
                      <div>
                        <p>{item.address}</p>
                      </div>

                       <div>
                        <p style={{fontWeight:"700"}}>Date - {item.history_data[0].last_update}</p>
                      </div>

                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p style={{backgroundColor: '#1d7f0e', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}}>Medium</p>
                            <button style={{backgroundColor: '#008CBA', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}} onClick = {redirectToHistoryPage(item.id)}>History</button>
                      </div>
                      
                      </div>
                      
                    </Popup>
                    </Marker>
                  ):
                  (item.category === "general" && item.status === "high")?(
                    <Marker position={[item.latitude,item.longitude]} 
                    icon={highMarkerIcon}
                    key= {idx}
                    >   
                    <Popup>
                      <div >
                        <div style={{width:"300px",height:'150px'}}>
                        <img src={item.history_data[0].imagePath} style={{backgroundSize:'cover',width:"300px",height:'150px'}} />
                        </div>
                      
                      <div>
                      <p style={{fontWeight:"700"}}>Spot Name - {item.name}</p>
                      </div>
                        
                      {/* <div>
                        <p style={{fontWeight:"700",color:"grey"}}>{item.locality}</p>
                      </div> */}
                      <div>
                        <p>{item.address}</p>
                      </div>

                       <div>
                        <p style={{fontWeight:"700"}}>Date - {item.history_data[0].last_update}</p>
                      </div>

                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p style={{backgroundColor: 'Red', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}}>High</p>
                            <button style={{backgroundColor: '#008CBA', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}} onClick = {redirectToHistoryPage(item.id)}>History</button>
                      </div>
                      
                      </div>
                      
                    </Popup>
                    </Marker>
                  ):
                  (item.category === "priority" && item.status === " ")?(
                    <Marker position={[item.latitude,item.longitude]} 
                    icon={normalPriorityMarkerIcon}
                    key= {idx}
                    >   
                    <Popup>
                      <div >
                        <div style={{width:"300px",height:'150px'}}>
                        <img src={item.defaultImage} style={{backgroundSize:'cover',width:"300px",height:'150px'}} />
                        </div>
                      
                      <div>
                      <p style={{fontWeight:"700"}}>Spot Name - {item.name}</p>
                      </div>
                        
                      {/* <div>
                        <p style={{fontWeight:"700",color:"grey"}}>{item.locality}</p>
                      </div> */}
                      <div>
                        <p>{item.address}</p>
                      </div>

                     <div>
                      <p style={{fontWeight:"700"}}>No Spot Covered today</p>
                    </div>
                      
                      </div>
                      
                    </Popup>
                    </Marker>
                  ):
                  (item.category === "priority" && item.status === "low")?(
                    <Marker position={[item.latitude,item.longitude]} 
                    icon={lowPriorityMarkerIcon}
                    key= {idx}
                    >   
                    <Popup>
                    <div >
                        <div style={{width:"300px",height:'150px'}}>
                        <img src={item.history_data[0].imagePath} style={{backgroundSize:'cover',width:"300px",height:'150px'}} />
                        </div>

                      <div style={{width:'250px'}}>
                        <p style={{fontWeight:"700"}}>Spot Name - {item.name}</p>
                      </div>


                       <div>
                        <p>{item.address}</p>
                      </div>

                      <div>
                        <p style={{fontWeight:"700"}}>Date - {item.history_data[0].last_update}</p>
                      </div>
                      
                      {/* <div>
                      <p style={{fontWeight:"700"}}>Date</p>
                        <p>{item.last_update}</p>
                      </div> */}
                        
                      {/* <div>
                        <p style={{fontWeight:"700",color:"grey"}}>{item.locality}</p>
                      </div> */}

                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p style={{backgroundColor: '#4CAF50', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}}>Low</p>
                            <button style={{backgroundColor: '#008CBA', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}} onClick = {redirectToHistoryPage(item.id)}>History</button>
                      </div>
                    </div>
                      
                    </Popup>
                    </Marker>
                  ):(item.category === "priority" && item.status === "medium")?(
                    <Marker position={[item.latitude,item.longitude]} 
                    icon={mediumPriorityMarkerIcon}
                    key= {idx}
                    >   
                    <Popup>
                    <div >
                        <div style={{width:"300px",height:'150px'}}>
                        <img src={item.history_data[0].imagePath} style={{backgroundSize:'cover',width:"300px",height:'150px'}} />
                        </div>

                      <div style={{width:'250px'}}>
                        <p style={{fontWeight:"700"}}>Spot Name - {item.name}</p>
                      </div>

                      <div>
                        <p>{item.address}</p>
                      </div>

                      <div>
                        <p style={{fontWeight:"700"}}>Date - {item.history_data[0].last_update}</p>
                      </div>

                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p style={{backgroundColor: '#1d7f0e', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}}>Medium</p>
                            <button style={{backgroundColor: '#008CBA', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}} onClick = {redirectToHistoryPage(item.id)}>History</button>
                      </div>
                      
                      {/* <div>
                      <p style={{fontWeight:"700"}}>Date</p>
                        <p>{item.last_update}</p>
                      </div> */}
                        
                      {/* <div>
                        <p style={{fontWeight:"700",color:"grey"}}>{item.locality}</p>
                      </div> */}
                      
                    </div>
                      
                    </Popup>
                    </Marker>
                  ):(item.category === "priority" && item.status === "high")?(
                    <Marker position={[item.latitude,item.longitude]} 
                    icon={highPriorityMarkerIcon}
                    key= {idx}
                    >   
                    <Popup>
                    <div >
                        <div style={{width:"300px",height:'150px'}}>
                        <img src={item.history_data[0].imagePath} style={{backgroundSize:'cover',width:"300px",height:'150px'}} />
                        </div>

                      <div style={{width:'250px'}}>
                        <p style={{fontWeight:"700"}}>Spot Name - {item.name}</p>
                      </div>

                      <div>
                        <p>{item.address}</p>
                      </div>

                      <div>
                        <p style={{fontWeight:"700"}}>Date - {item.history_data[0].last_update}</p>
                      </div>

                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p style={{backgroundColor: 'Red', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}}>High</p>
                            <button style={{backgroundColor: '#008CBA', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer'}} onClick = {redirectToHistoryPage(item.id)}>History</button>
                      </div>
                      
                      {/* <div>
                      <p style={{fontWeight:"700"}}>Date</p>
                        <p>{item.last_update}</p>
                      </div> */}
                        
                      {/* <div>
                        <p style={{fontWeight:"700",color:"grey"}}>{item.locality}</p>
                      </div> */}
                    </div>
                      
                    </Popup>
                    </Marker>
                  ):
                  <Marker position={[item.longitude,item.latitude]} 
                  icon={normalPriorityMarkerIcon}
                  key= {idx}
                  >
                  <Popup>
                  <div >
                      <div style={{width:"300px",height:'150px'}}>
                        <img src={item.defaultImage} style={{backgroundSize:'cover',width:"0px",height:'150px'}} />
                      </div>
                    
                      <div>
                        <p style={{fontWeight:"700"}}>Spot Name - {item.name}</p>
                      </div>
                      
                      {/* <div>
                        <p style={{fontWeight:"700",color:"grey"}}>{item.locality}</p>
                      </div> */}
                     <div>
                        <p>{item.address}</p>
                      </div>

                      <div>
                        <p style={{fontWeight:"700"}}>No Spots covered today.</p>
                      </div>
                    
                    </div>
                    
                  </Popup>
                  </Marker>
                  }):  '' }
          
        </MapContainer>
      </div>


    </Fragment>

  )


};

export default Leaflet;
